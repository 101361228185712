export const statusMap = {
  0: 'shop.orders.draft',
  1: 'shop.orders.current',
  2: 'shop.orders.processing',
  3: 'shop.orders.readyForPickup',
  4: 'shop.orders.pickedUp',
  5: 'shop.orders.finished',
  6: 'shop.orders.canceled',
  7: 'shop.orders.rejectedByPayment',
}

export const pickupStatusMap = {
  0: 'shop.orders.pickUp.draft',
  1: 'shop.orders.pickUp.current',
  2: 'shop.orders.pickUp.processing',
  3: 'shop.orders.pickUp.readyForPickup',
  4: 'shop.orders.pickUp.pickedUp',
  5: 'shop.orders.pickUp.pickupFinished',
  6: 'shop.orders.pickUp.canceled',
  7: 'shop.orders.pickUp.rejectedByPayment',
}

export const formatPickUpTime = (d: Date): string => {
  const hours = d.getHours()
  const minutes = d.getMinutes()
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}
