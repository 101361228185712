export const BrazeCustomAttributes = [
  'Registration_date',
  'Current_points',
  'Has_used_pick_up',
  'Monthly_membership',
  'Monthly_membership_activation_date',
  'Membership_in_lifetime',
]

export type BrazeUserDefaultDetails = {
  Registration_date: string
  Current_points: number
  Has_used_pick_up: boolean
  Monthly_membership: boolean
  Monthly_membership_activation_date: string
  Membership_in_lifetime: boolean
}

export function SetBrazeDetailsToSessionStorage(
  _id: string,
  details: BrazeUserDefaultDetails
): void {
  if (_id) {
    sessionStorage.setItem(
      `Braze:UserDefaultDetails:${_id}`,
      JSON.stringify(details)
    )
  }
}

export function ClearBrazeDetailsToSessionStorage(_id: string): void {
  sessionStorage.removeItem(`Braze:UserDefaultDetails:${_id}`)
}

export function GetBrazeDetailsToSessionStorage(
  _id: string
): BrazeUserDefaultDetails {
  if (_id) {
    try {
      const str = sessionStorage.getItem(`Braze:UserDefaultDetails:${_id}`)
      if (str) {
        return JSON.parse(str)
      }
    } catch (error) {
      return null
    }
  }
}

export async function logBrazeRateCourier(
  User_Id: string,
  Cart_value: number,
  Rating: string
): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('UserRatedCourier', { User_Id, Time: new Date(), Cart_value, Rating })
}

export async function logBrazeRateOrder(
  User_Id: string,
  Cart_value: number,
  Rating: string
): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('UserRatedOrder', { User_Id, Time: new Date(), Cart_value, Rating })
}

export async function logBrazeFailedOrder(
  User_Id: string,
  Cart_value: number,
  status: string
): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('FailedOrder', {
    User_Id,
    Time: new Date(),
    Cart_value,
    Statuses: [status],
  })
}
export async function logPurchase(
  User_Id: string,
  Cart_id: string,
  Cart_value: number
): Promise<void> {
  const { logPurchase } = await import('@braze/web-sdk')
  logPurchase(Cart_id, Cart_value, 'GEL', null, {
    User_Id,
    Time: new Date(),
    Cart_value,
  })
}

export async function logBrazeSuccessfullOrder(
  User_Id: string,
  Cart_value: number
): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('SuccessfulOrder', { User_Id, Time: new Date(), Cart_value })
}

export async function logBrazeEventCreateCart(User_Id: string): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('FirstProductAddedToCart', { User_Id, Time: new Date() })
}

export async function logBrazeEventCleanCart(User_Id: string): Promise<void> {
  const log = await _logBrazeCustomEvent()
  log('LastItemRemovedFromCart', { User_Id, Time: new Date() })
}

async function _logBrazeCustomEvent() {
  const { logCustomEvent } = await import('@braze/web-sdk')
  return logCustomEvent
}
