import React, { useContext, useEffect, useState, FC } from 'react'

import dynamic from 'next/dynamic'
import OrderListEmpty from './OrderListEmpty/OrderListEmpty'
import UserContext from 'contexts/UserContext'
import { useUser } from '@auth0/nextjs-auth0'
import { Button } from '@components/ui'
import { useRouter } from 'next/router'
import { UseTranslate } from 'hooks/Translate'

const PastOrders = dynamic(
  () => import('@components/Orders/PastOrders/PastOrders'),
  {
    ssr: false,
  }
)

const CurrentOrders = dynamic(
  () => import('@components/Orders/CurrentOrders/CurrentOrders'),
  {
    ssr: false,
  }
)

const Orders: FC = () => {
  const {
    userCurrentOrders,
    userPastOrders,
    loadUserCurrentOrders,
    loadUserPastOrders,
    loading,
    userCurrentOrdersLoading,
    userPastOrdersLoading,
    userPastOrdersTotalCount,
  } = useContext(UserContext)
  const { user } = useUser()

  const router = useRouter()
  const [translate] = UseTranslate()

  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const userOrdersLoading = userCurrentOrdersLoading && userPastOrdersLoading

  useEffect(() => {
    if (user) {
      loadUserCurrentOrders(user?.sub)
      loadUserPastOrders(user?.sub)
    }
  }, [user])

  return (
    <>

      {!userCurrentOrdersLoading && userCurrentOrders.length ? <CurrentOrders orders={userCurrentOrders} loading={loading} /> : <></>}

      {!userPastOrdersLoading && userPastOrders.length ?
        <PastOrders
          orders={userPastOrders}
          detailed={false}
          showMore={
            userPastOrdersTotalCount > 15 && (
              <div className="d-flex justify-content-center align-items-center pb-2">
                <Button
                  size={330}
                  type="secondary"
                  onClick={() => {
                    router.push(`/${lang}/profile/pastOrders/`)
                  }}
                  text={translate('shop.orders.pastOrders.showMore')}
                />
              </div>
            )
          }
        /> : <></>}

      {!userOrdersLoading && !userPastOrders.length && !userCurrentOrders.length ? <OrderListEmpty /> : <></>}
    </>
  )
}

export default Orders
