import React, { useContext, useEffect, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import {
  FiHeart,
  FiLogOut,
  FiMapPin,
  FiShoppingBag,
  FiStar,
} from 'react-icons/fi'
import {
  AddFriendIcon,
  CreditCardGrey,
  UserProfileIconGrey,
  WalletIcon,
} from '@components/ui/Icon/Icon'
import { UseTranslate } from 'hooks/Translate'
import cn from 'classnames'
import styles from './AuthUser.module.scss'
import { useRouter } from 'next/router'
import { useUser, UserProfile } from '@auth0/nextjs-auth0'
import { BrowserView, MobileView } from 'react-device-detect'
import RequestHelper from '@utils/RequestHelper'
import AppContext from 'contexts/AppContext'
import UserContext from 'contexts/UserContext'
import { ClearBrazeDetailsToSessionStorage } from '@utils/BrazeDefaultUserDetails'

export interface User extends UserProfile {
  firstName: string
}

interface ChatWindow extends Window {
  Intercom?: any
  intercomSettings?: any
  attachEvent?: any
}

export default function AuthUser() {
  const [translate] = UseTranslate()

  const [isOpen, setIsOpen] = useState(false)
  const {
    walletPaymentIsActive,
    setWalletPaymentIsActive,
    primeMembershipIsActive,
    setPrimeMembershipIsActive,
    setOrderRateIsActive,
    referalIsActive,
    setReferalIsActive,
  } = useContext(AppContext)

  const { primeConfiguration, loadPrimeConfiguration } = useContext(UserContext)

  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const calculateEwalletBalance = async () => {
    const pointAdditionActiveResponse = await RequestHelper.catalog.get(
      '/configs/calculatePointForOrder'
    )
    setWalletPaymentIsActive(
      pointAdditionActiveResponse?.data?.data?.walletPayment
    )
    setPrimeMembershipIsActive(
      pointAdditionActiveResponse?.data?.data?.primeMembership
    )
    setOrderRateIsActive(
      pointAdditionActiveResponse?.data?.data?.orderRateIsActive
    )
    setReferalIsActive(pointAdditionActiveResponse?.data?.data?.referalIsActive)
  }
  const data = useUser()
  const user = {
    firstName: String(data?.user?.firstName || ''),
  }

  useEffect(() => {
    calculateEwalletBalance()
    loadPrimeConfiguration()
  }, [])

  const toggleOpen = () => setIsOpen(!isOpen)

  const cnBackdrop = cn({
    [styles.backdrop]: true,
    [styles.backdropVisible]: isOpen,
  })

  const cnMenuMobile = cn({
    [styles.mobileMenu]: true,
    [styles.menuOpen]: isOpen,
  })

  const cnMenuDesktop = cn({
    [styles.desktopMenu]: true,
    [styles.menuOpen]: isOpen,
  })

  const redirect = (tab: string) => {
    setIsOpen(false)
    router.push(`/${lang}/profile/${tab}`)
  }

  const redirectToReferral = () => {
    setIsOpen(false)
    router.push(`/${lang}/referral`)
  }

  const logOut = async () => {
    //authService?.signOut()
    const w: ChatWindow = window
    w.Intercom('shutdown')
    await RequestHelper.cas.delete('users/session/invalidateSession')
    localStorage.removeItem('cartId')
    ClearBrazeDetailsToSessionStorage(data.user.userId as string)
    w.open(`/api/auth/logout?lang=${localStorage.getItem('lang')}`, '_self')
  }

  //useEffect(() => {
  //document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  //paddingInlineEnd(isOpen)
  //}, [isOpen])

  const ulList = (
    <ul>
      <li>
        <a onClick={() => redirect('orders')}>
          <FiShoppingBag size={22} />{' '}
          {translate('ecommerce.authUserMenu.orders')}
        </a>
      </li>
      <li>
        <a onClick={() => redirect('mapPin')}>
          <FiMapPin size={22} /> {translate('ecommerce.authUserMenu.addresses')}
        </a>
      </li>
      <li>
        <a onClick={() => redirect('creditCard')}>
          <CreditCardGrey />
          {translate('ecommerce.authUserMenu.paymentMethods')}
        </a>
      </li>
      <li>
        <a onClick={() => redirect('userProfile')}>
          <UserProfileIconGrey width="22" height="22" />{' '}
          {translate('ecommerce.authUserMenu.profile')}
        </a>
      </li>
      <li>
        <a onClick={() => redirect('favorite')}>
          <FiHeart size={22} /> {translate('ecommerce.authUserMenu.favorites')}
        </a>
      </li>

      {walletPaymentIsActive && (
        <li>
          <a onClick={() => redirect('ewallet')}>
            <WalletIcon /> {translate('ecommerce.authUserMenu.ewallet')}
          </a>
        </li>
      )}
      {referalIsActive && (
        <li>
          <a onClick={() => redirectToReferral()}>
            <AddFriendIcon /> {translate('ecommerce.authUserMenu.addFriend')}
          </a>
        </li>
      )}
      {primeMembershipIsActive &&
        (primeConfiguration?.deliveryFee || primeConfiguration?.pickupFee) && (
          <li>
            <a onClick={() => redirect('prime')}>
              <FiStar size={22} /> {translate('ecommerce.authUserMenu.prime')}
            </a>
          </li>
        )}
      <li>
        <a onClick={() => logOut()}>
          <FiLogOut size={22} /> {translate('ecommerce.authUser.logout')}
        </a>
      </li>
    </ul>
  )

  return (
    <div className={styles.wrapper}>
      <div className={cnBackdrop} onClick={toggleOpen}></div>
      <div onClick={toggleOpen}>
        <BrowserView>
          <div className={styles.welcome}>
            <span className={styles.emoji}>&#128075;</span>
            <span className={styles.userName}>
              {user?.firstName || translate('authUser.hello')}
            </span>
            <div style={{ marginLeft: 4 }}>
              {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className={styles.welcomeMobile}>
            <span className={styles.emoji}>&#128075;</span>
            <span className={styles.userName}>
              {user?.firstName || translate('authUser.hello')}
            </span>
          </div>
        </MobileView>
      </div>
      <div className={cnMenuMobile}>
        <div className={styles.mobileMenu__header}>
          <h4>{translate('authUser.account')}</h4>
          <svg width="36" height="36" viewBox="0 0 36 36" onClick={toggleOpen}>
            <path
              d="M27 9L9 27"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9L27 27"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={styles.mobileMenu__welcome}>
          <span className={styles.message}>{translate('authUser.hello')}</span>
          <span className={styles.userName}>{user?.firstName}</span>
        </div>
        {ulList}
      </div>
      <div className={cnMenuDesktop}>{ulList}</div>
    </div>
  )
}
