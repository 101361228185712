import React, { FC, useContext, useEffect, useState } from 'react'

import { UseTranslate } from 'hooks/Translate'
import { Menu } from '..'
import { FiGrid } from 'react-icons/fi'

import styles from './SubHeader.module.scss'
import { useRouter } from 'next/router'
import Link from 'next/link'
import CategoryContext from 'contexts/CategoryContext'
import AppContext from 'contexts/AppContext'
import { getMenuIconUrl } from '@utils/getImage'


const SubHeader: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<unknown>>>> = () => {
  const router = useRouter()

  const [translate] = UseTranslate()
  const [categories, setCategories] = useState<Category[]>([])
  const { categoriesData } = useContext(CategoryContext)
  const { menuConfig, menuConfigLoading, selectedLanguage } = useContext(AppContext)
  const [headerSubCatergories, setHeaderSubCatergories] = useState([])
  const customHeaderItems = [
    // {
    //   _id: "recipes",
    //   name: {
    //     ge: 'რეცეპტები',
    //     en: 'recipes',
    //   },
    //   badge: () => {
    //     return (
    //       <div className={styles.badgeCnt}>
    //         <span className={styles.text}>New</span>
    //       </div>
    //     )
    //   },
    //   url: {
    //     ge: `/ge/recipes`,
    //     en: `/en/recipes`

    //   }
    // }
  ]
  useEffect(() => {
    if (categoriesData) {
      loadCategories(categoriesData)
    }
  }, [categoriesData])

  useEffect(() => {
    if (menuConfig) {
      const headerSubCatergories = menuConfig.filter(
        (item) => item.position === 1
      )
      setHeaderSubCatergories([...headerSubCatergories, ...customHeaderItems])
    }
  }, [menuConfig])

  const loadCategories = (data: Category[]) => {
    const categories = data.filter((category: Category) => !category.parent)
    setCategories(categories)
  }

  const openLink = (item) => {
    const langStr = (selectedLanguage.key || 'ge').toString()
    if (item.url && item.url[langStr] && item.url[langStr].startsWith('/')) {
      router.push(item.url[langStr])
    } else {
      window.open(item.url[(selectedLanguage.key || 'ge').toString()], '_blank')
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className="metad-container">
        <div className={styles.container}>
          <Menu
            text={translate('ecommerce.categories')}
            backdrop={true}
            type={'bubble'}
            icon={<FiGrid />}
            arrow={true}
            hover={false}
          >
            {categories.map((category) => (
              <Link
                key={category._id}
                href={location.origin + `/${(selectedLanguage.key || 'ge')}/category/${category.nameSlug}`}
              >
                <a className={styles.like_h2}>
                  {category.name[(selectedLanguage.key || 'ge') as string] || '-'}
                </a>
              </Link>
            ))}
          </Menu>
          {headerSubCatergories.map((item) => {
            return (
              <section className={styles.quicklink} key={item._id}>
                <a key={item._id} onClick={() => openLink(item)}>
                  {item.icon &&
                    item.icon[(selectedLanguage.key || 'ge')?.toString()] &&
                    item.icon[(selectedLanguage.key || 'ge')?.toString()]['imageId'] && (
                      <div className={styles.icon}>
                        <img
                          src={getMenuIconUrl(item, (selectedLanguage.key || 'ge') as string)}
                          alt={item?.name[(selectedLanguage.key || 'ge') as string] || '-'}
                        />
                      </div>
                    )}
                  <div className={styles.text}>
                    {item.name[(selectedLanguage.key || 'ge')?.toString()]}
                  </div>
                  {item.badge ? item.badge() : ''}
                </a>
              </section>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SubHeader
