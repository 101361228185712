import { FC, useContext, useEffect } from 'react'
import NextHead from 'next/head'
import { DefaultSeo } from 'next-seo'
import config from '@config/seo.json'
import AppContext from 'contexts/AppContext'
import { useRouter } from 'next/router'
import CategoryContext from 'contexts/CategoryContext'
import UserContext from '../../../contexts/UserContext'
import { useUser } from '@auth0/nextjs-auth0'
import Script from 'next/script'

interface Props {
  pageProps: {
    pages?: unknown[]
  }
}

const Head: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
  >
> = ({ pageProps }) => {
  const { loadConfig, loadMenuConfig } = useContext(AppContext)
  const { loadCategories } = useContext(CategoryContext)
  const { loadProfile } = useContext(UserContext)
  const { user } = useUser()

  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const GOOGLE_API_TOKEN = process.env.NEXT_PUBLIC_GOOGLE_MAPS_TOKEN

  useEffect(() => {
    loadConfig()
    loadMenuConfig(lang)

    window['fbAsyncInit'] = function () {
      window['FB'].init({
        appId: process.env.NEXT_PUBLIC_FB_APP_ID,
        autoLogAppEvents: false,
        xfbml: false,
        version: 'v11.0',
      })
    }

    //load braze
    import('@braze/web-sdk').then(({ initialize, openSession }) => {
      initialize(process.env.NEXT_PUBLIC_BAYZE_API_KEY, {
        baseUrl: process.env.NEXT_PUBLIC_BAYZE_API_HOST,
        enableLogging: true,
      })
      openSession()
    })
  }, [])

  useEffect(() => {
    const language = lang?.toString() || 'ge'
    loadCategories(language)
  }, [lang])

  const loadUserInfo = async (userId: string, data): Promise<User> => {
    return loadProfile(userId)
  }

  interface ChatWindow extends Window {
    Intercom?: any
    intercomSettings?: any
    attachEvent?: any
  }

  useEffect(() => {
    const w: ChatWindow = window
    ;(function (...args) {
      const w: ChatWindow = window
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(args)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/w8dk6tdk'
          const x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (w.attachEvent) {
          setTimeout(() => {
            w.attachEvent('onload', l)
          }, 0)
        } else {
          setTimeout(() => {
            w.addEventListener('load', l, false)
          }, 0)
        }
      }
    })()

    const data = {
      app_id: 'w8dk6tdk',
      platform: 'Shop Website',
      custom_launcher_selector: '#intercom-chat-help-link',
    }

    //w.Intercom('shutdown')
    if (user) {
      loadUserInfo(user?.sub, data).then((profile) => {
        if (profile) {
          setTimeout(() => {
            data['user_profile'] = profile?.courierId
              ? `${process.env.NEXT_PUBLIC_ADMIN_UI}/couriers/${profile['courierId']}`
              : `${process.env.NEXT_PUBLIC_ADMIN_UI}/user/${profile['_id']}`
            data['user_id'] = profile['_id']
            data['phone'] = profile['phoneNumber']
            data['name'] = profile['firstName']

            if (profile['email']) {
              data['email'] = profile['email']
            } else {
              data['email'] = ''
            }
            if (profile['isCourier']) {
              data['user_type'] = 'Courier'
            } else {
              data['user_type'] = 'Customer'
            }

            data['created_at'] = new Date().getTime()

            if (profile['firstName'] && profile['lastName']) {
              data['full_name'] =
                profile['firstName'] + ' ' + profile['lastName']
            } else {
              data['full_name'] = ''
            }

            w.intercomSettings = data
            w.Intercom('boot', data)
          }, 1000)
        }
      })
    } else {
      w.intercomSettings = data
      w.Intercom('boot', data)
    }
  }, [user])

  return (
    <>
      <link rel="icon" type="image/x-icon" href="favicon.ico" />
      {router.query.PageSpeed == 'noscript' ||
      router.query.searchText ||
      router.query.searchId ? (
        <NextHead>
          <meta name="robots" content="noindex,nofollow" />
        </NextHead>
      ) : (
        <>
          <DefaultSeo {...config} />

          <NextHead>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1, shrink-to-fit=no"
            />

            {!router.pathname.includes('category') &&
            !router.pathname.includes('product') ? (
              <>
                <link
                  rel="canonical"
                  href={`https://2nabiji.ge/${lang || 'ge'}`}
                />
                <link
                  rel="alternate"
                  href={'https://2nabiji.ge/ge'}
                  hrefLang="ka-GE"
                />
                <link
                  rel="alternate"
                  href={'https://2nabiji.ge/en'}
                  hrefLang="en-GE"
                />
                <link
                  rel="alternate"
                  href={'https://2nabiji.ge/en'}
                  hrefLang="x-default"
                />
              </>
            ) : (
              <></>
            )}

            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  '@context': 'https://schema.org',
                  '@type': 'LocalBusiness',
                  name: '2NABIJI - Online Supermarket and Grocery Delivery',
                  logo: 'https://2nabiji.ge/2-nabiji-logo.png',
                  url: 'https://2nabiji.ge/en',
                  sameAs: [
                    'https://www.facebook.com/2nabijiofficial',
                    'https://www.instagram.com/2nabiji',
                    'https://www.youtube.com/@2i2nabiji72',
                    'https://www.linkedin.com/company/2-ნაბიჯი-2-nabiji',
                  ],
                  contactPoint: {
                    '@type': 'ContactPoint',
                    telephone: '+995 32 200 22 00',
                    contactType: 'customer service',
                    email: 'info@orinabiji.ge',
                  },
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: '39/41 Navtlughi Str.',
                    addressLocality: 'Tbilisi',
                    postalCode: '0113',
                    addressCountry: 'Georgia',
                  },
                  openingHoursSpecification: [
                    {
                      '@type': 'OpeningHoursSpecification',
                      dayOfWeek: [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday',
                      ],
                      opens: '10:00',
                      closes: '22:00',
                    },
                  ],
                  telephone: '+995 32 200 22 00',
                  priceRange: '$',
                  image: 'https://2nabiji.ge/api/files/2-nabiji-url-logo.svg',
                }),
              }}
            />
          </NextHead>
        </>
      )}

      <Script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js"
        strategy="lazyOnload"
      ></Script>
      <Script
        async
        defer
        strategy="lazyOnload"
        src={`https://maps.googleapis.com/maps/api/js?language=${
          !lang || lang == 'ge' ? 'ka' : lang
        }&key=${GOOGLE_API_TOKEN}&libraries=places&callback=Function.prototype`}
      ></Script>

      {/* Google Tag Manager (noscript) */}
      <Script
        async
        defer
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');`,
        }}
      ></Script>
      {/* End Google Tag Manager */}

      {/* Hotjar Tracking Code */}
      <Script
        async
        defer
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      ></Script>
      {/* End Hotjar Tracking Code*/}
    </>
  )
}

export default Head
