import io, { Socket } from 'socket.io-client'

const NEXT_SOCKET_API_URI = process.env.NEXT_PUBLIC_SOCKET_API_URI

export default class SocketService {
  static socket: Socket
  static connectedUserId: string

  static init(userId: string): void {
    try {
      if (this.connectedUserId === userId) {
        return
      }
      this.socket = io(`${NEXT_SOCKET_API_URI}/shopApp?userId=${userId}`, {
        forceNew: true,
        transports: ['websocket', 'polling'],
      })

      this.socket.on('connect', () => {
        this.connectedUserId = userId
      })

      this.socket.on('disconnect', () => {
        this.connectedUserId = ''
      })

      this.socket.connect()
    } catch (err) {
      console.log(err)
    }
  }

  static listen(eventName: string, cb: (...args: any) => void): void {
    if (this.socket) {
      this.socket.on(eventName, cb)
    }
  }

  static disconnect(): void {
    this.socket.disconnect()
  }
}
