import { FC, useEffect, useState } from "react";
import styles from './IngredintChangeButton.module.scss'
import cn from 'classnames'
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Alternatives from "./alternativeList/Alternatives";
interface IngredientChangeButton {
    opened: boolean, onOpen: () => void,
    onClose: () => void;
    alternatives: Product[],
    onChange: (cur: Product) => void

}
export const IngredientChangeButton: FC<
    React.PropsWithChildren<
        React.PropsWithChildren<React.PropsWithChildren<IngredientChangeButton>>
    >
> = ({
    opened, onOpen, alternatives, onClose, onChange
}) => {

        const [cnMenuDesktop, setSdMenuStyle] = useState<string>();

        useEffect(() => {
            setSdMenuStyle(cn({
                [styles.desktopMenu]: true,
                [styles.menuOpen]: opened,
            }))
        }, [opened])



        return alternatives && alternatives.length ? (

            <div className={styles.changeProductBtnCnt}>
                <div className={styles.changeButton} onClick={() => onOpen()}>
                    შეცვლა
                    {
                        opened ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />
                    }
                </div>
                <div className={cnMenuDesktop}>
                    <Alternatives visible={opened} onSelect={(pr: Product) => onChange(pr)} onClose={() => onClose()} alternatives={alternatives} />
                </div>
            </div>
        ) : <></>
    }